import React from "react";
import { Link } from "gatsby";
import Layout from "../parts/Layout";
import { PageComponentProps } from "../../types/types";

import * as pageStyles from "../global-styles/page.module.css";

export const Community = (props: PageComponentProps) => {
  const { clairnoteName, otherClairnoteName, urlDir } = props;
  const title = "Community";
  const description =
    "Various ways the Clairnote community can connect, help each other, and share resources.";
  return (
    <Layout title={title} description={description} {...props}>
      <article className="page">
        <h1 className={`title is-3 ${pageStyles.pageTitle}`}>{title}</h1>
        <div>
          <h2 className="title is-5">Connect</h2>
          <p>
            Subscribe to the{" "}
            <Link to={urlDir + "newsletter"}>Clairnote Newsletter</Link> to
            receive occasional news and updates about Clairnote by email.
          </p>
          <p>
            Join the{" "}
            <a
              href="https://groups.google.com/g/clairnote"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Clairnote Group
            </a>{" "}
            (on Google Groups) to connect with others who are interested in{" "}
            {clairnoteName} (and {otherClairnoteName}). This is our public
            mailing list for discussing {clairnoteName}, sharing experiences
            learning it and using it, getting help with creating sheet music,
            and anything else related to {clairnoteName}.
          </p>
          <p>
            Send us an <Link to={urlDir + "contact/"}>email</Link>. It's always
            good to hear from people interested in Clairnote.
          </p>
          {/* <p>
            Reach out and tell us how you found out about Clairnote and what you
            like most about Clairnote. Join our public mailing list / discussion
            group, or send us an email privately.
          </p> */}
          <h2 className="title is-5">Show Your Support</h2>
          <p>
            Show your support by following Clairnote on{" "}
            <a
              href="https://mastodon.social/@clairnote"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Mastodon
            </a>{" "}
            and{" "}
            <a
              href="https://www.facebook.com/ClairnoteMusicNotation"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Facebook
            </a>
            .
          </p>
          <h2 className="title is-5">Spread the Word</h2>
          <p>
            Help increase awareness of {clairnoteName}, for example by:
            <ul>
              <li>
                Talking about it with people you know who might be interested.
              </li>
              <li>
                Sharing links to the Clairnote website through email or social
                media.
              </li>
              <li>
                Sharing links to Clairnote blog posts when they are published.
              </li>
            </ul>
          </p>
          <h2 className="title is-5">Create and Share Sheet Music</h2>
          <p>
            Help us make sheet music available in {clairnoteName}. Maybe you are
            already creating {clairnoteName} sheet music? (See{" "}
            <Link to={urlDir + "software/"}>Software: LilyPond</Link>.) Sharing
            sheet music is a great way we can help each other out and make it
            easier for others to enjoy using {clairnoteName}.
          </p>
          <p>
            Please <Link to={urlDir + "contact/"}>contact us</Link> if you would
            like to help us expand the collection of sheet music that is freely
            available from the Clairnote website. Due to copyright laws, the
            sheet music must be in the{" "}
            <a
              href="https://en.wikipedia.org/wiki/Public_domain"
              target="_blank"
              rel="external noopener noreferrer"
            >
              public domain
            </a>{" "}
            or licensed under a relevant{" "}
            <a
              href="https://creativecommons.org/"
              target="_blank"
              rel="external noopener noreferrer"
            >
              Creative Commons
            </a>{" "}
            license.
          </p>
          <p>
            Some sources of sheet music files that can be converted into{" "}
            {clairnoteName} include:
            <ul>
              <li>
                <a
                  href="https://www.mutopiaproject.org/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  The Mutopia Project
                </a>
              </li>
              <li>
                <a
                  href="https://imslp.org/wiki/Main_Page"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  International Music Score Library Project (IMSLP) / Petrucci
                  Music Library
                </a>
              </li>
              <li>
                <a
                  href="https://musescore.com/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  MuseScore.com
                </a>
              </li>
              <li>
                <a
                  href="https://thesession.org/"
                  target="_blank"
                  rel="external noopener noreferrer"
                >
                  The Session
                </a>
              </li>
            </ul>
          </p>
        </div>
      </article>
    </Layout>
  );
};

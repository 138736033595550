import { PageProps } from "gatsby";
import React from "react";

import { Community } from "../../components/pages/Community";

import { dnProps } from "../../js/utils";

const AboutPage = (props: PageProps) => (
  <Community {...{ ...dnProps, ...props }} />
);

export default AboutPage;
